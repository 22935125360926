@use "theme/reset";
@use "theme/components/modal";
@use "theme/components/loading";
@use "theme/components/inputs";
@use "theme/components/buttons";
@use "theme/components/keyframes";
@use 'theme/components/ion-content';
@use 'theme/variables';
@use "colors";
@use "layout-variables";
@use "typography";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/swiper.min.css';
@import "~swiper/swiper-bundle.css";
@import '~swiper/modules/navigation/navigation.min.css';
@import '~swiper/modules/pagination/pagination.min.css';

html, body {
  font-size: 2.5vw;

  @media (min-width: 400px) {
    font-size: 10px;
  }
}

body.scanner-active {
  --background: transparent !important;
  --ion-background-color: transparent !important;

  app-main {
    background: transparent !important;
  }

  .desktop {
    background: transparent;
  }
}

button.error-button {
  background: none !important;
  color: var(--color-primary) !important;
  text-decoration: underline;
}

.d-none {
  display: none;
}

ion-searchbar {
  padding-left: 0 !important;

  &:focus-within {
    .searchbar-input-container .searchbar-search-icon {
      opacity: 0 !important;
    }
  }

  .searchbar-input-container {
    .searchbar-input {
      padding-right: 2rem;
      padding-left: 1.5rem !important;

      border-radius: 5rem;
      border: .1rem solid var(--color-border-primary);

      box-shadow: none;
      color: var(--text-color-secondary);
      background-color: transparent;

      height: 3.5rem;
      font-size: 1.5rem;
      
      &:not(:placeholder-shown) {
        border-color: var(--color-border-secondary);
      }
    }

    .searchbar-search-icon {
      left: unset;
      top: calc(3.5rem / 2 - 1rem);
      right: 1rem;
      width: 2rem;
      height: 2rem;
    }
  }
}

ion-searchbar ion-icon {
  color: var(--color-border-primary) !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 100rem var(--ion-background-color) inset !important;
  -webkit-text-fill-color: #000000 !important;
}

.leaflet-container {
  background: #FFFFFF !important;

  & img.leaflet-image-layer {
    max-width: none !important
  }
}

.pull-to-refresh {
  transform: translateY(calc(var(--ion-safe-area-left) + 3rem));

  position: relative;
  z-index: 0;

  overflow: hidden;
}

hr.divider {
  height: 6px;
  width: 100%;

  margin: 0;

  background: var(--color-border-primary);
}

.loading {
  animation: spining 1s infinite;
  color: var(--color-icon-primary);
}

.full-size-background {
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;

  z-index: -1;

  object-fit: cover;
}

.blur {
  filter: blur(6px);
}
