.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  cursor: pointer;

  border: none;
  border-radius: var(--button-border-radius);

  width: 100%;
  height: 5rem;

  font-weight: 500;
  font-size: 1.5rem;
  text-align: center;

  transition: background 0.3s, color 0.3s;

  > ion-icon {
    transition: color 0.3s;
  }

  &:disabled {
    color: var(--color-gray-700);
    background: var(--color-gray-400);

    > ion-icon {
      color: var(--color-gray-700);
    }
  }

  &--primary {
    @extend .button;

    background: var(--color-primary);
    color: var(--text-color-primary-contrast);

    > ion-icon {
      color: var(--text-color-primary-contrast);
    }

    &__transparent {
      @extend .button--primary;

      background: transparent;
      color: white;
      border: 0;
    }
  }

  &--primary-contrast {
    @extend .button;

    background: var(--color-icon-primary-contrast);
    color: var(--text-color-tag);
  }

  &--secondary {
    @extend .button;

    position: relative;

    background: var(--color-background-paper);
    border: 1px solid var(--color-border-tertiary);
    color: var(--text-color-tag);

    height: 3rem;
  }

  &--tertiary {
    @extend .button;

    height: auto;
    width: fit-content;

    padding: 0 1rem;

    font-weight: normal;
    background: transparent;
    color: var(--text-color-secondary);

    &__subhead {
      font-weight: 500;
      color: var(--text-color-tag);
    }
  }

  &--danger {
    @extend .button;

    background: var(--color-error-primary);
    color: var(--text-color-primary-contrast);

    > ion-icon {
      color: var(--text-color-primary-contrast);
    }
  }
}
