:root {
  --color-background: #FAFAFA;
  --color-background-paper: #FFFFFF;

  --color-primary-light: #E14D53;
  --color-primary: linear-gradient(90deg, #E0055B 0%, #570376 100%);
  --color-primary-dark: linear-gradient(90deg, #E1015C, #570078);

  --color-secondary-light: #FFFFFF;
  --color-secondary: #FFFFFF;
  --color-secondary-dark: #FFFFFF;

  --color-success-primary: #1FB853;
  --color-success-secondary: #DDFFE6;

  --color-error-primary: #D62929;
  --color-error-secondary: #FCD9DA;

  --text-color-primary: #333333;
  --text-color-primary-dark: #2A2A2A;
  --text-color-primary-contrast: #FFFFFF;
  --text-color-secondary: #6B788B;
  --text-color-tag: #E1015C;

  --color-icon-primary: #E1015C;
  --color-icon-primary-contrast: #FFFFFF;
  --color-icon-primary-dark: #570078;
  --color-icon-secondary: #FFFFFF;
  --color-icon-disabled: #6B788B;
  --color-icon-bright: #333333;

  --color-border-primary: #C3C3C3;
  --color-border-secondary: #9AA6B6;
  --color-border-tertiary: #E1015C;

  --color-gray-400: #BDBDBD;
  --color-gray-500: #9E9E9E;
  --color-gray-700: #616161;
  --color-gray-900: #40404059;

  --color-pink-400: #E1015C;
  --color-red-400: #E10101;
  
  --color-black: #0D0D0D;

  --color-gradient: linear-gradient(180deg, #570376 2.03%, #e0055b 79.5%);

  // Adding the correct color to components such as IonAlert
  --ion-color-primary: var(--text-color-tag);
  --ion-text-color: var(--text-color-primary);
  --ion-background-color: var(--color-background-paper);
}
