ion-modal {
  overflow: hidden !important;
  --border-radius: 1.6rem;
  --box-shadow: 0 1rem 1.5rem -.3rem rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.successful-register-modal {
  --background: var(--color-primary-dark);
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.successful-password-recover-modal {
  --background: var(--color-primary-dark);
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.mission-modal {
  --height: 85%;
  --width: 80%;

  --background: transparent;

  --ion-backdrop-color: rgba(0, 0, 0, 0.8) !important;
  --box-shadow: none;

  --overflow: hidden;

  ion-toolbar {
    --background: transparent;
    --border-style: none;

    text-align: center;
  }
}

.quiz-modal {
  --height: 40rem;
  --width: 80%;
}

.points-modal {
  --max-height: 26rem;
  --width: 80%;
}

.coupon-modal {
  --background: transparent;
  --border-radius: 0;

  &::part(content) {
    @media only screen and (hover: hover) and (pointer: fine) {
      --height: 100%;
      --width: 100%;
    }
  }
}

.mission-feedback-modal {
  --height: 100vh;
  --width: 100vw;
  --background: transparent;
  --border-radius: 0;

  --backdrop-opacity: var(--ion-backdrop-opacity, 0.6);
}

.modal-terms {
  --border-radius: 0;

  &::part(content) {
    @media only screen and (hover: hover) and (pointer: fine) {
      --height: 100%;
      --width: 100%;
    }
  }
}

.modal-video {
  --border-radius: 0;
  --background: var(--color-black);

  &::part(content) {
    @media only screen and (hover: hover) and (pointer: fine) {
      --height: 100%;
      --width: 100%;
    }
  }
}

.modal-mission-attention {
  --width: 90vw;
  --height: 30rem;
}

.availability-product-modal {
  --height: 30%;
  --width: 80%;
}

.border-radius {
  --border-radius: 1.6rem 1.6rem 0 0;
  --width: 100%;
}

.ion-modal::part(backdrop) {
  background: rgba(210, 210, 210, 0.521);
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: rgb(14 116 144);
  --color: white;
}

.hydrated {
  overflow: auto;
}

.confirm-modal::part(content) {
  height: 21rem;
  width: 88%;
}

.forgot-password-modal::part(content) {
  height: 22rem;
  width: 88%;
}

.pwa-modal {
  --height: 22rem;
  --width: 80%;
}
