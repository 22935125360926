.loading {
  width: 100%;
  height: 3rem;
  color: #E1015C;
  animation: spining 1s infinite;
  margin-top: 2rem;
  pointer-events: none;
}

@keyframes spining {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
